import React from "react";
import Categories from "./Categories";

export default function Home() {
  return (
    <>
      <Categories />
    </>
  );
}
